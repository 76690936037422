<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Cadastre receitas de produtos como por exemplo bolos, mousses, salgados e etc. Ideal para produtos de panificadora.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md-8 col-lg-9 col-xxl-10 mb-3">
							<label><i class="far fa-shopping-bag color-theme font-11 me-1"></i> Nome</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarReceitas" placeholder="Busque por nome da receita" />
                  </div>
						<div class="col-md-4 col-lg-3 col-xxl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarReceitas"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar receitas')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                        </div>
                     </div>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>
					</div>
					<div class="row">
						<receita v-for="(receita, index) in pesquisa.retorno.resultado" :key="index" :receita="receita" :index="index" 
							@editar="abrirModal($event)" @lancar="abrirModalLancamento($event)" />
					</div>
				</div>
			</div>
      </div>

		<!-- modalReceita -->
      <div class="modal fade" id="modalReceita" tabindex="-1" aria-labelledby="modalReceitaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalReceitaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} receita {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-produto-tab" data-bs-toggle="pill" data-bs-target="#pills-produto" type="button" role="tab" aria-controls="pills-produto" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-md-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-necessarios-tab" data-bs-toggle="pill" data-bs-target="#pills-necessarios" type="button" role="tab" aria-controls="pills-necessarios" aria-selected="false">
											<i class="far fa-exclamation-triangle font-12"></i><span class="ms-1 d-none d-md-inline"> Itens necessários</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-produzidos-tab" data-bs-toggle="pill" data-bs-target="#pills-produzidos" type="button" role="tab" aria-controls="pills-produzidos" aria-selected="false">
											<i class="far fa-equals font-12"></i><span class="ms-1 d-none d-md-inline"> Produtos produzidos</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-produto" role="tabpanel" aria-labelledby="pills-produto-tab">
								<div class="card">
									<div class="card-body p-12">
										<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Nome *</label>
										<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
										<div class="alert alert-secondary alert-dismissible fade show shadow-none mb-0 pe-3 mt-2" role="alert">
											<strong>Receitas</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
											<span>Defina os itens necessários para produzir a receita. Exemplo: A cada 1 lata de leite condensado e creme de leite é produzido 400g de mousse.</span>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-necessarios" role="tabpanel" aria-labelledby="pills-necessarios-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="col-12 mb-3">
											<h6 class="mb-0 mt-1 font-15">
												<span class="mb-0 font-14 weight-400">{{ modal.insumos.length }} insumo(s) encontrado(s)</span>
												<a href="javascript:;" class="float-end font-16" @click="toggleAddProduto('item necessário')"><i class="fas fa-plus color-theme"></i></a>
											</h6>
										</div>
										
										<produto v-for="(produto, index) in modal.insumos" :key="index" :produto="produto" :index="index" :campos="{'quantidade': true}" 
											@remover="removerProduto($event, 'item necessário')" />
										
										<div class="col-12 my-5 text-center" v-if="modal.insumos.length == 0">
											<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-produzidos" role="tabpanel" aria-labelledby="pills-produzidos-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="col-12 mb-3">
											<h6 class="mb-0 mt-1 font-15">
												<span class="mb-0 font-14 weight-400">{{ modal.produtos.length }} produto(s) encontrado(s)</span>
												<a href="javascript:;" class="float-end font-16" @click="toggleAddProduto('produto produzido')"><i class="fas fa-plus color-theme"></i></a>
											</h6>
										</div>
	
										<produto v-for="(produto, index) in modal.produtos" :key="index" :produto="produto" :index="index" :campos="{'quantidade': true}" 
											@remover="removerProduto($event, 'produto produzido')" />

										<div class="w-100 my-5 text-center" v-if="modal.produtos.length == 0">
											<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarReceita">Salvar</button>
						<button type="button" class="btn btn-outline-primary" v-if="modal.id" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<!-- modalLancamento -->
      <div class="modal fade" id="modalLancamento" tabindex="-1" aria-labelledby="modalLancamentoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalLancamentoLabel">Lançar receita {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-1">
							<div class="card-body px-12 pt-12 pb-0">
								<div class="row">
									<div class="col-sm-6 mb-3">
										<label><i class="far fa-boxes color-theme font-10 me-1"></i> Quantidade de receitas *</label>
										<input type="text" class="form-control" v-model="modal.quantidade" maxlength="10" />
									</div>
									<div class="col-sm-6 mb-3">
										<label><i class="far fa-store color-theme font-10 me-1"></i> Loja *</label>
										<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="modal.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção"
											@input="buscarEstoques(modal.loja)">
											<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
											<template v-slot:no-options>
												<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
											</template>
										</v-select>
										<select name="Mobile" class="form-control" v-model="modal.loja" @change="buscarEstoques(modal.loja)">
											<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
										</select>
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-box color-theme font-10 me-1"></i> Estoque saída *</label>
										<v-select name="Desktop" :options="estoques" v-model="modal.estoqueSaida" label="nome" :reduce="e => e.id" placeholder="Selecione uma opção">
											<template v-slot:no-options>
												<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
											</template>
										</v-select>
										<select name="Mobile" class="form-control" v-model="modal.estoqueSaida">
											<option v-for="(option, index) in estoques" :key="index" :value="option.id">{{ option.nome }}</option>
										</select>
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-box color-theme font-10 me-1"></i> Estoque entrada *</label>
										<v-select name="Desktop" :options="estoques" v-model="modal.estoqueEntrada" label="nome" :reduce="e => e.id" placeholder="Selecione uma opção">
											<template v-slot:no-options>
												<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
											</template>
										</v-select>
										<select name="Mobile" class="form-control" v-model="modal.estoqueEntrada">
											<option v-for="(option, index) in estoques" :key="index" :value="option.id">{{ option.nome }}</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						<div class="card mb-1">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="necessarios-tab" data-bs-toggle="pill" data-bs-target="#necessarios" type="button" role="tab" aria-controls="necessarios" aria-selected="true">
											<i class="far fa-exclamation-triangle font-12"></i><span class="ms-1 d-none d-md-inline"> Itens necessários</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="produzidos-tab" data-bs-toggle="pill" data-bs-target="#produzidos" type="button" role="tab" aria-controls="produzidos" aria-selected="false">
											<i class="far fa-equals font-12"></i><span class="ms-1 d-none d-md-inline"> Produtos produzidos</span>
										</button>
									</li>
                        </ul>
                     </div>
                  </div>

						<div class="tab-content mb-150" id="pills-tabContent">
							<div class="tab-pane fade show active" id="necessarios" role="tabpanel" aria-labelledby="necessarios-tab">
								<produto v-for="(produto, index) in modal.insumos" :key="index" :produto="produto" :index="index" :campos="{}" />
							</div>
							<div class="tab-pane fade" id="produzidos" role="tabpanel" aria-labelledby="produzidos-tab">
								<produto v-for="(produto, index) in modal.produtos" :key="index" :produto="produto" :index="index" :campos="{}" />
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="gerarReceita">Finalizar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<modalProdutoPreco :modalAnterior="'#modalReceita'" @addProduto="addProduto($event)" />

		<impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="new Date()" :cabecalho="{
			'Nome receita': modal.nome == null ? ' - ' : modal.nome
			}" :itensKey="{'nome': 'Item necessário - Descrição', 'quantidade': 'Quantidade'}" :itens="modal.insumos" 
			:itens2Key="{'nome': 'Item produzido - Descrição', 'quantidade': 'Quantidade'}" :itens2="modal.produtos" />
	</div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import produto from '@/components/modelos/Produto2.vue'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import receita from '@/components/automacao/receitas/Receita.vue'
import modalProdutoPreco from '@/components/modelos/ModalProdutoPreco.vue'

export default {
	name: 'Receitas',
	data: function () {
		return {
			pesquisa: {'nome': '', 'retorno': {'nome': null, 'resultado': []}},
			modal: {'id': null, 'nome': null, 'insumos': [], 'produtos': [], 'produto': {'tipo': null}, 'loja': null, 'estoqueSaida': null, 'estoqueEntrada': null, 'quantidade': '1.000'},
			estoques: []
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
		'modal.quantidade': function (newVal) {
			if (newVal != null && String(newVal).trim().length > 0) {
				this.modal.insumos.forEach(i => i.quantidade = parseFloat(i.quantidadeOriginal) * parseFloat(newVal));
				this.modal.produtos.forEach(i => i.quantidade = parseFloat(i.quantidadeOriginal) * parseFloat(newVal));
			} else {
				this.modal.insumos.forEach(i => i.quantidade = parseFloat(i.quantidadeOriginal) * 1);
				this.modal.produtos.forEach(i => i.quantidade = parseFloat(i.quantidadeOriginal) * 1);
			}
      }
	},
	components: {
		receita, produto, impressao, modalProdutoPreco
	},
	methods: {
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Receitas',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nome'], 
				'lista': null
			})
      },
		abrirModal : function (receita) {
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			if (receita != null) {
				$('#modalReceita').modal('show')
				this.buscarReceita(receita)
			} else {
				$('#modalReceita').modal('show')
			}
		},
		abrirModalLancamento : function (receita) {
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			this.buscarReceita(receita)
			$('#modalLancamento').modal('show')
		},
		buscarEstoques : function (idLoja) {
			/* eslint-disable-next-line no-unused-vars */
			return new Promise((resolve, reject) => {
				let loja = this.dadosUsuario.usuarioLojas.find(l => l.id == idLoja)
				this.modal.estoqueEntrada = null
				this.modal.estoqueSaida = null
				
				if (loja != null) {
					this.estoques = loja.estoques
				} else {
					this.estoques = []
				}
	
				resolve()
			})
		},
		buscarReceitas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/searchReceitas',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarReceita : function (receita) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'automacao/getReceita',
				params: {
					id: receita.id
				}
			}).then(response => {
				this.modal = {
					'id': response.data.id, 
					'nome': response.data.nome, 
					'insumos': response.data.insumos,
					'produtos': response.data.produtos,
					'produto': {'tipo': null},
					'loja': null, 
					'estoqueSaida': null, 
					'estoqueEntrada': null, 
					'quantidade': '1'
				}

				this.modal.insumos.forEach(i => i.quantidadeOriginal = i.quantidade);
				this.modal.produtos.forEach(i => i.quantidadeOriginal = i.quantidade);

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		toggleAddProduto : function (tipo) {
			this.modal.produto = {'tipo': tipo}
			$('#modalReceita').modal('hide')
			$('#modalProdutoPreco').modal('show')
		},
		addProduto : function (produto) {
			if (this.modal.produto.tipo == 'produto produzido') {
				this.modal.produtos.push(JSON.parse(JSON.stringify(produto)))
			} else {
				this.modal.insumos.push(JSON.parse(JSON.stringify(produto)))
			}
			
			this.modal.produto = {'tipo': null}
		},
		removerProduto : function (index, tipo) {
			if (tipo == 'produto produzido') {
				this.modal.produtos.splice(index, 1)
			} else {
				this.modal.insumos.splice(index, 1)
			}
		},
		salvarReceita : function () {
			if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 || this.modal.insumos.length == 0 || this.modal.produtos.length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			} else if (this.modal.produtos.filter(p => !/^[0-9.]+$/.test(p.quantidade) || parseFloat(p.quantidade) <= 0).length > 0 ||
				this.modal.insumos.filter(p => !/^[0-9.]+$/.test(p.quantidade) || parseFloat(p.quantidade) <= 0).length > 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Existem produtos com quantidade inválida!'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/saveReceita',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalReceita').modal('hide')
				this.buscarReceitas()

				this.$toast.fire({
					icon: 'success',
					title: 'Receita salva!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		gerarReceita : function () {
			if (this.modal.loja == null || this.modal.estoqueEntrada == null || this.modal.estoqueSaida == null ||!/^[0-9.]+$/.test(this.modal.quantidade)) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			Swal.fire({
            icon: 'warning',
            title: 'Lançar receita?',
            text: ('Essa ação é irreversível, você irá lançar '+ this.modal.quantidade +'x receitas de "'+ this.modal.nome +'"'),
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'automacao/gerarReceita',
						headers: {'Content-Type': 'application/json'},
						data: this.modal

					}).then(() => {
						$('#modalLancamento').modal('hide')

						this.$toast.fire({
							icon: 'success',
							title: 'Receita lançada!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>